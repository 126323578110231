import React from 'react'
import { parseHTML } from './../../helpers/parseHTML';
import animateScrollTo from 'animated-scroll-to';

interface props {
  data: any
  mobile: boolean
}

interface state {
  activeLevel: string
  contentOffset: number
  achievementOffset: number
  learningOffset: number
  levelOffsets: Array<any>
}

export default class SequenceCurriculum extends React.Component<props, state> {

  constructor(props) {
    super(props);
    this.state = {
      activeLevel: '',
      contentOffset: 0,
      achievementOffset: 0,
      learningOffset: 0,
      levelOffsets: []
    };
  }

  
  componentDidMount = () => {
    this.setFirstLevelActive();

    if (window.innerWidth < 768) {
      setTimeout(() => this.setOffsets(), 500);
    }
  }

  setFirstLevelActive = () => {
    const levels = this.props.data.pageData.victorianCurriculumConnection.levels;
    if (levels.length) {
      this.setState({activeLevel: levels[0].levelTitle})
    } else {
      this.setState({activeLevel: 'Learning Progressions'})
    }
  }

  setOffsets = () => {
    const levels = this.props.data.pageData.victorianCurriculumConnection.levels;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const learningSection = document.getElementById('learning-progressions');
    let levelOffsets = []

    for (let i = 0; i < levels.length; i++) {
      const levelEl = document.getElementById(`level-content-${i}`)
      levelOffsets.push(levelEl.getBoundingClientRect().top + scrollTop)
    }

    this.setState({
      learningOffset: learningSection.getBoundingClientRect().top + scrollTop,
      levelOffsets: levelOffsets
    })
  }

  setTabActive = (tabName, index) => {
    if (window.innerWidth < 768 === false) {
      this.setState({activeLevel: tabName})
    } else {
      if (tabName === 'Learning Progressions') {
        animateScrollTo(this.state.learningOffset - 20, {speed: 800})
      } else {
        animateScrollTo(this.state.levelOffsets[index] - 20, {speed: 800})
      }
    }
  }


  render () {
    let data = this.props.data;
    let pageData = data.pageData;
    let vccData = pageData.victorianCurriculumConnection;
    return (
      <div className={`sequence-curriculum`} id={`curriculum`}>
        <p className="sequence-curriculum__title">{vccData.title}</p>
        <div className="sequence-curriculum__tabs-header">
          Contents
        </div>
        <div className="sequence-curriculum__tabs">
          {vccData.levels.map((level, index) => {
            return (
              <button 
                key={`level-button-${index}`}
                className={`sequence-curriculum__tab ${this.state.activeLevel === level.levelTitle ? 'active' : ''}`}
                onClick={() => this.setTabActive(level.levelTitle, index)}
              >
                {level.levelTitle}
              </button>
            )
          })}
          <button 
            className={`sequence-curriculum__tab ${this.state.activeLevel === 'Learning Progressions'  ? 'active' : ''}`}
            onClick={() => this.setTabActive('Learning Progressions', 0)}
          >
            Learning Progressions
          </button>
        </div>
        <div className="sequence-curriculum__content">
          {vccData.levels.map((level, index) => {
            return (
              <div 
                className={`
                  sequence-curriculum__content-inner progressions-descriptions 
                  ${this.state.activeLevel === level.levelTitle ? 'active' : ''}
                `}
                id={`level-content-${index}`}
                key={`level-content-${index}`}
              >
                <p className="sequence-curriculum__content-header">{level.levelTitle}</p>
                {parseHTML(level.levelContent)}
              </div>
            )
          })}
          <div 
            className={`
              sequence-curriculum__content-inner progressions-descriptions 
              ${this.state.activeLevel === 'Learning Progressions' ? 'active' : ''}
            `}
            id={`learning-progressions`}
          >
            <p className="sequence-curriculum__content-header">Learning Progressions</p>
            {parseHTML(vccData.learningProgressionsText)}
          </div>
        </div>
      </div>
    )
  }
}