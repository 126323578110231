import React from 'react'
import SequenceTextBlock from './sequence-textblock';
import SequenceCurriculum from './sequence-curriculum';

interface props {
  data: any
  stage: boolean
  mobile: boolean
}

interface state {
}

export default class SequenceContent extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;
    
    return (
      <div className={`sequence-content`}>
        {data.pageData.whatIsThisSequenceAbout !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="What is this sequence about?" 
            id={`whatIsThisSequenceAbout`}
            content={data.pageData.whatIsThisSequenceAbout}
          /> 
        : ''}
        {data.pageData.stages.length > 0 ?
          <SequenceTextBlock 
            data={data} 
            title="Overview of stages" 
            id={`overview`}
            content={data.pageData.overview}
          /> 
        : ''}
        {data.pageData.priorKnowledge !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="Prior knowledge" 
            id={`priorKnowledge`}
            content={data.pageData.priorKnowledge}
          /> 
        : ''}
        {data.pageData.teachingStrategies !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="Teaching strategies" 
            id={`teachingStrategies`}
            content={data.pageData.teachingStrategies}
          /> 
        : ''}
        {data.pageData.vocabulary !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="Vocabulary" 
            id={`vocabulary`}
            content={data.pageData.vocabulary}
          /> 
        : ''}
        {data.pageData.assessment !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="Assessment" 
            id={`assessment`}
            content={data.pageData.assessment}
          /> 
        : ''}
        {data.pageData.victorianCurriculumConnection !== null ?
          <SequenceCurriculum 
            data={data}
            mobile={this.props.mobile}
          /> 
        : ''}
        {/* Remove in future iteration */}
        {/* {data.pageData.engagingParentsAndCarers !== '' ?
          <SequenceTextBlock 
            data={data} 
            title="Engaging Parents and Carers" 
            id={`engagingParentsAndCarers`}
            content={data.pageData.engagingParentsAndCarers}
          /> 
        : ''} */}
      </div>
    )
  }
}
