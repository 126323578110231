import React from 'react'

import { parseHTML } from './../../helpers/parseHTML';
import Masonry from 'react-masonry-css'
import AnimatedLink from './../global/animatedLink';
// @ts-ignore
import ArrowRightBlue from '../../svgs/arrow-right-blue.svg'

interface props {
  data: any
  title: string
  id: string
  content: any
}

interface state {
}

export default class SequenceTextBlock extends React.Component<props, state> {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  truncate = (str, maxLen, separator = ' ') => {
    if (str == undefined) return;
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;
    const breakpointColumnsObj = {
      default: 2,
      995: 1,
    };
    
    return (
      <div className={`sequence-textblock`} id={this.props.id}>
        <p className="sequence-textblock__title">{this.props.title}</p>
          <div className="sequence-textblock__content">
            {parseHTML(this.props.content)}
          </div>
          {this.props.id == 'overview' ? 
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="sequence-textblock__overview-stages"
              columnClassName="sequence-textblock__overview-stages-column"
            >
              {pageData.stages.map((stage, index) => {
                return (
                  <li 
                    key={`stage-${index}`}
                    className="sequence-textblock__overview-stage overview"
                  >
                    <p className="overview__title">{stage.stageTitle}</p>
                    <p className="overview__intentions-header">Suggested Learning Intentions</p>
                    <p className="overview__intentions">{parseHTML(stage.suggestedLearningIntensions)}</p>
                    <AnimatedLink
                      to={`/${pageData.slug}/stages/${stage.stageRelativeUrl}`}
                      className="overview__link"
                    >
                      Explore <ArrowRightBlue/>
                    </AnimatedLink>
                  </li>
                )
              })}
            </Masonry>
          :''}

      </div>
    )
  }
}