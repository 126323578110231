import React from 'react'
import Img from 'gatsby-image/withIEPolyfill';
// @ts-ignore
import SequenceHeaderBreak from '../../svgs/sequence-header-break.svg'
// @ts-ignore
import ArrowRightDarkRed from '../../svgs/arrow-right-dark-red.svg' 
import AnimatedLink from './../global/animatedLink';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

interface props {
  data: any
}

interface state {
  url: string
  titleSite: string
}

export default class SequenceHeader extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      titleSite: " | DET Learning Sequences"
    };

  }

  componentDidMount = () => {
    this.setState({url: window.location.href})
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;
  
    
    const levels = JSON.parse(pageData.levels);
    const subjects = JSON.parse(pageData.subjects);
    const primaryLevel = JSON.parse(pageData.primaryLevel);

    return (
      <div className={`sequence-header`}>
        <div className="sequence-header__left">
          <h1 className="sequence-header__title">{pageData.title}</h1>
          <SequenceHeaderBreak/>
          <p className="sequence-header__subject">
            {subjects !== null ? subjects.map((subject, i) => {
                if (i < subjects.length - 1) {
                  return subject + ' / '
                } else {
                  return subject
                }
              }) : ''
            }
          </p>
          <p className="sequence-header__level">
            {levels !== null ? levels.map((level, i) => {
                if (primaryLevel && (primaryLevel[0] === level)) {
                  return <span className="primary" key={i}>{level}</span>
                }
                return <span key={i}>{level}</span>
              }) : ''
            }
          </p>
        </div>
        <div className="sequence-header__right">
          <Img fluid={pageData.sequenceImage.fluid} alt={pageData.sequenceImage.alt}/>
        </div>
        <div className="sequence-header__bottom">
          <div className="sequence-header__bottom-left">
            {pageData.stages.length > 0 ? 
              <AnimatedLink to={`/${pageData.slug}/stages`} className="sequence-header__stages-button">
                View the stages <ArrowRightDarkRed/>
              </AnimatedLink>
            : ''}
          </div>
          <div className="sequence-header__bottom-right">
            <div className="sequence-header__share">
              <p className="sequence-header__share-text">Share this</p>
              <div className="sequence-header__share-buttons">
                <FacebookShareButton 
                  className="sequence-header__share-button"
                  url={this.state.url}
                >
                  <FacebookIcon round={true} size={30}/>
                </FacebookShareButton>
                <TwitterShareButton 
                  className="sequence-header__share-button"
                  url={this.state.url}
                >
                  <TwitterIcon round={true} size={30}/>
                </TwitterShareButton>
                <LinkedinShareButton 
                  className="sequence-header__share-button"
                  url={this.state.url}
                >
                  <LinkedinIcon round={true} size={30}/>
                </LinkedinShareButton>
                <EmailShareButton 
                  className="sequence-header__share-button"
                  subject={pageData.title + this.state.titleSite}
                  body={pageData.seoDescription}
                  url={this.state.url}
                >
                  <EmailIcon round={true} size={30}/>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}