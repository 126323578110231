import React from 'react'
import Layout from '../layouts/layout';
import SEO from '../layouts/seo';
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import SequenceRibbons from '../sequence/sequence-ribbons';
import SequenceLastUpdated from './../sequence/sequence-lastupdated';
import SequenceHeader from './../sequence/sequence-header';
import SequenceContent from './../sequence/sequence-content';
import ReadingProgress from 'react-reading-progress-plus'
import animateScrollTo from 'animated-scroll-to';
import Sidenav from './../global/sidenav';
import queryString from 'query-string';
import {isIE} from 'react-device-detect';

interface props {
  data: any
  browser: string
  transitionStatus: string
  pageContext: any
  entry: any
}

interface state {
  tabletOrMobile: boolean
  renderNav: boolean
  progress: boolean
}

class Staticpage extends React.Component<props, state> {
  resizeEventListener: Function

  constructor(props) {
    super(props);
    this.state = {
      tabletOrMobile: false,
      renderNav: false,
      progress: false
    };
    // props.data.pageData.levels = JSON.parse(props.data.pageData.levels);
  }

  componentDidMount = () => {
    this.windowResizeEvent();
    this.checkForLinking();
    setTimeout(() => this.startProgressBar(), 500);
  }

  startProgressBar = () => this.setState({progress: true});

  windowResizeEvent = () => {
    this.checkTabletOrMobile();
    let ticking = false;

    // Resize event with throttling
    window.addEventListener('resize', this.resizeEventListener = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.checkTabletOrMobile();
          ticking = false;
        });
        ticking = true;
      }
    }); 
  }

  checkForLinking = () => {
    setTimeout(() => {
      const parsedQuerystring = queryString.parse(location.search);

      if (parsedQuerystring.scrollto !== undefined) {
        let scrollID = parsedQuerystring.scrollto;
        let elOffset = this.getOffset(scrollID);
        let marginOffset = 25;
        if (isIE) marginOffset = 115;
        let includeTitleOffset = elOffset - marginOffset;
        
        // Remove query string      
        if (history.pushState) {
          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
          window.history.pushState({path:newurl},'',newurl);
        }

        animateScrollTo(includeTitleOffset, {speed: 1000});
      }
    }, 500);
  }

  getOffset = (id) => {
    const idEl = document.getElementById(id);
    let elOffset = 0;
    
    if (idEl !== null) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      elOffset = idEl.getBoundingClientRect().top + scrollTop;
    }

    return elOffset
  }

  checkTabletOrMobile = () => {
    if (window.innerWidth < 995) {
      this.setState({
        tabletOrMobile: true,
        renderNav: true
      })
    } else {
      this.setState({
        tabletOrMobile: false,
        renderNav: true
      })
    }
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('resize', this.resizeEventListener);
  }

  render () {
    let data = this.props.data;
    let {transitionStatus} = this.props;
    let context = this.props.pageContext;
    let parentsArray = [{url:'learning-areas', title:'Learning areas'}]

    return (
      <>
        {this.state.progress ?
          <ReadingProgress targetEl="#sequence-content"/>
        :''}
        <SEO
          title={data.pageData.title} 
          description={data.pageData.seoDescription} 
        />
        <Layout
          data={data}
          class={data.pageData.slug}
          pageTitle={data.pageData.title} 
          parents={parentsArray}
          transitionStatus={transitionStatus}
        >
          <SequenceRibbons
            data={data}
            browser={this.props.browser}
          />
          <div className={`sequence-layout middle-container`}>
            <div className="sequence-layout__left">
              {!this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={false}
                  pageType={`sequence`}
                />
              :''}
            </div>
            <div className="sequence-layout__right" id={`sequence-content`}>
              <SequenceHeader
                data={data}
              />
              {this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={true}
                  pageType={`sequence`}
                />
              :''}
              <SequenceContent
                data={data}
                stage={context.stage}
                mobile={this.state.tabletOrMobile}
              />
              <SequenceLastUpdated
                data={data}
                stage={context.stage}
              />
            </div>
          </div>
        </Layout>
      </>
    )
  }
}


export const query = graphql`
  query($slug: String!) {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    pageData: datoCmsSequence(slug: {eq: $slug}) {
      slug
      title
      seoDescription
      lastUpdatedText
      levels
      primaryLevel
      subjects
      searchTags
      overview
      assessment
      teachingStrategies
      sequenceListingDotPoints
      whatIsThisSequenceAbout
      priorKnowledge
      vocabulary
      meta {
        updatedAt
      }
      stages {
        stageTitle
        stageRelativeUrl
        suggestedLearningIntensions
        lastUpdatedText
        successCriteria
        meta {
          updatedAt
        }
        contentSections {
          sectionContent
          sectionTitle
        }
        stageCurriculumDescription {
          contentDescriptionsContent {
            ... on DatoCmsContentDescriptionsText {
              contentText
            }
            ... on DatoCmsContentDescriptionsTitle {
              contentTitle
            }
          }
        }
      }
      sequenceImage {
        alt
        url
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsFluid
        }
      }
      victorianCurriculumConnection {
        title
        learningProgressionsText
        levels {
          ... on DatoCmsLevel {
            levelTitle
            levelContent
          }
        }
      }   
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  })
)(Staticpage);