import React from 'react'
import moment from 'moment-es6'
import animateScrollTo from 'animated-scroll-to';
import ReactTooltip from 'react-tooltip'
// @ts-ignore
import ArrowRightDarkRed from '../../svgs/arrow-right-dark-red.svg' 
import AnimatedLink from './../global/animatedLink';

interface props {
  data: any
  stage: boolean
}

interface state {
}

export default class SequenceLastUpdated extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  getLastUpdated = () => {
    let timestamp = this.props.data.pageData.meta.updatedAt;
    return moment(timestamp).format('MMMM Do YYYY');
  }

  backToTop = () => {
    animateScrollTo(0, {
      speed: 500
    })
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;
    
    return (
      <>
        {pageData.stages.length > 0 ? 
            <AnimatedLink to={`/${pageData.slug}/stages`} className="sequence-footer__stages-button">
              View the stages <ArrowRightDarkRed/>
            </AnimatedLink>
        :''}
        <div className={`sequence-footer`}>
          <div 
            className={`sequence-footer__last-updated`}
            data-tip={pageData.lastUpdatedText.length ? 'Update notes: ' + pageData.lastUpdatedText : ''}
          >
            <span className={`sequence-footer__last-updated-text`}>Last updated: {this.getLastUpdated()}</span>
          </div>
          <button 
            className={`sequence-footer__back-top`}
            onClick={() => this.backToTop()}
          >
            Back to top
          </button>
          <ReactTooltip/>
        </div>
      </>
    )
  }
}
